var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.hiddenActivator)?_c('Menu',{class:[
    'multi-select',
    {
      'multi-select_showed-list': _vm.showedList,
      'multi-select_loading': _vm.loading,
    },
  ],attrs:{"value":_vm.showedList,"close-on-content-click":false,"offset-y":"","right":"","bottom":"","allow-overflow":"","rounded":"0","transition":"slide-y-reverse-transition","content-class":"overflow-visible"},on:{"update:showed-menu":_vm.onUpdateShowedMenu},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('div',_vm._g(_vm._b({staticClass:"multi-select__input"},'div',attrs,false),on),[_c('v-text-field',{staticClass:"white",attrs:{"loading":_vm.loading,"dense":"","readonly":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.inputIcon)?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.inputIcon)+" ")]):_vm._e(),_c('span',{staticClass:"black--text text-truncate flex-grow-1 d-inline-block",style:({
              width: '0',
              fontSize: '14px',
              lineHeight: '1',
            })},[_vm._v(" "+_vm._s(_vm.inputLabel)+" ")])]},proxy:true},{key:"append",fn:function(){return [(!_vm.hiddenSelectedInfo)?_c('span',{class:[
              'text-no-wrap font-weight-medium',
              {
                'success--text':
                  _vm.localSelectedItemsLength === _vm.localTotalItemsLength,
                'warning--text':
                  _vm.localSelectedItemsLength > 0 &&
                  _vm.localSelectedItemsLength < _vm.localTotalItemsLength,
                'red--text': !_vm.localSelectedItemsLength,
              },
            ],style:({
              fontSize: '11px',
              lineHeight: '1',
              marginRight: '2px',
              marginTop: '3px',
            })},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.dashboard.selected_from_to", _vm.localSelectedItemsLength, _vm.localTotalItemsLength ))+" ")]):_vm._e(),_c('v-icon',{style:({
              transform: _vm.showedList ? 'rotate(180deg)' : undefined,
            })},[_vm._v(" $dropdown ")])]},proxy:true}],null,true)})],1)]}},{key:"content",fn:function(){return [_c('v-card',{staticClass:"py-4",attrs:{"min-width":_vm.minWidth,"rounded":"0","elevation":"0"}},[(!_vm.localHiddenSearch)?_c('div',{staticClass:"px-4 d-flex"},[_c('v-text-field',{staticClass:"flex-shrink-1 flex-grow-1",style:({ width: 0 }),attrs:{"label":_vm.$vuetify.lang.t('$vuetify.dashboard.search'),"value":_vm.searchInput,"clearable":"","dense":"","outlined":"","hide-details":""},on:{"input":_vm.onUpdateSearchInput}})],1):_vm._e(),(!_vm.localHiddenSearch)?_c('v-divider',{staticClass:"ma-4"}):_vm._e(),(!_vm.hiddenSelectAllControls)?_c('div',{staticClass:"d-flex align-center justify-space-between px-4"},[_c('v-btn',{staticClass:"px-2 mr-2",attrs:{"color":"success","small":"","outlined":"","height":"24"},on:{"click":function($event){return _vm.toggleSelectAll(true)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.choose_all"))+" ")]),_c('v-btn',{staticClass:"px-2",attrs:{"color":"error","small":"","outlined":"","height":"24"},on:{"click":function($event){return _vm.toggleSelectAll(false)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.clear_all"))+" ")])],1):_vm._e(),(!_vm.hiddenSelectAllControls)?_c('v-divider',{staticClass:"mb-3 mx-4 mt-4"}):_vm._e(),_c('v-progress-linear',{style:({
          visibility: _vm.searching ? 'visible' : 'hidden',
          opacity: _vm.searching ? '1' : '0',
          top: '-15px',
        }),attrs:{"indeterminate":"","color":"primary"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredItems.length === 0),expression:"filteredItems.length === 0"}],staticClass:"text-caption-1 text-center mb-0 px-4"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.noDataText"))+" ")]),_c('VuetifyLazyComponent',{staticClass:"multi-select__items overscroll-behavior-contain",attrs:{"items":_vm.filteredItems,"bench":_vm.virtualScrollBench,"item-height":_vm.itemHeight,"max-height":_vm.maxHeight,"component-name":"VVirtualScroll"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center overflow-hidden",style:({
              height: `${_vm.itemHeight}px`,
            })},[_c('v-card',{staticClass:"d-flex align-center user-select-none rounded-lg flex-grow-1 flex-shrink-1 py-0 pl-2 mx-3",attrs:{"min-height":_vm.itemHeight,"width":"0","elevation":"0"},on:{"click":function($event){return _vm.toggleItem(item[_vm.itemValue])},"mouseenter":function($event){return _vm.onMouseEnterItem(item, $event)},"mouseleave":function($event){return _vm.onMouseLeaveItem(item, $event)}}},[_vm._t("icon",null,{"item":item}),_c('div',{staticClass:"flex-grow-1",style:({ width: '0px' })},[_vm._t("item",function(){return [_c('p',{staticClass:"text-caption-1 mb-0 black--text text-break text-truncate"},[_vm._t("item-text",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText])+" ")]},{"item":item})],2),_vm._t("after-text",null,{"item":item})]},{"item":item})],2),_c('VuetifyLazyComponent',{staticClass:"my-0 flex-shrink-0 ml-3 py-0",style:({
                  pointerEvents: 'none',
                }),attrs:{"input-value":_vm.isSelectedItem(item[_vm.itemValue]),"ripple":false,"dense":"","hide-details":"","component-name":"VCheckbox"}})],2),_vm._t("after-item",null,{"item":item})],2)]}}],null,true)})],1),_vm._t("append-content")]},proxy:true}],null,true)}):_c('v-card',{staticClass:"py-4",attrs:{"min-width":_vm.minWidth,"rounded":"0"}},[(!_vm.localHiddenSearch)?_c('div',{staticClass:"px-4 d-flex"},[_c('v-text-field',{staticClass:"flex-shrink-1 flex-grow-1",style:({ width: 0 }),attrs:{"label":_vm.$vuetify.lang.t('$vuetify.dashboard.search'),"value":_vm.searchInput,"clearable":"","dense":"","outlined":"","hide-details":""},on:{"input":_vm.onUpdateSearchInput}})],1):_vm._e(),(!_vm.localHiddenSearch)?_c('v-divider',{staticClass:"ma-4"}):_vm._e(),(!_vm.hiddenSelectAllControls)?_c('div',{staticClass:"d-flex align-center justify-space-between px-4"},[_c('v-btn',{staticClass:"px-2 mr-2",attrs:{"color":"success","small":"","outlined":"","height":"24"},on:{"click":function($event){return _vm.toggleSelectAll(true)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.choose_all"))+" ")]),_c('v-btn',{staticClass:"px-2",attrs:{"color":"error","small":"","outlined":"","height":"24"},on:{"click":function($event){return _vm.toggleSelectAll(false)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.clear_all"))+" "),_c('v-icon',{staticClass:"flex-shrink-0 ml-1",style:({ opacity: 0.4 }),attrs:{"size":"18","right":""}},[_vm._v(" $close ")])],1)],1):_vm._e(),(!_vm.hiddenSelectAllControls)?_c('v-divider',{staticClass:"mb-3 mx-4 mt-4"}):_vm._e(),_c('v-progress-linear',{style:({
      visibility: _vm.searching ? 'visible' : 'hidden',
      opacity: _vm.searching ? '1' : '0',
      top: '-15px',
    }),attrs:{"indeterminate":"","color":"primary"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredItems.length === 0),expression:"filteredItems.length === 0"}],staticClass:"text-caption-1 text-center mb-0 px-4"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.noDataText"))+" ")]),_c('VuetifyLazyComponent',{staticClass:"multi-select__items overscroll-behavior-contain",attrs:{"items":_vm.filteredItems,"bench":_vm.virtualScrollBench,"item-height":_vm.itemHeight,"max-height":_vm.maxHeight,"component-name":"VVirtualScroll"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-card',{staticClass:"d-flex align-center user-select-none rounded-lg flex-grow-1 flex-shrink-1 py-0 pl-2 mx-3",attrs:{"min-height":_vm.itemHeight,"elevation":"0"},on:{"click":function($event){return _vm.toggleItem(item[_vm.itemValue])}}},[_vm._t("icon",null,{"item":item}),_c('div',{staticClass:"flex-grow-1",style:({ width: '0px' })},[_vm._t("item",function(){return [_c('p',{staticClass:"text-caption-1 mb-0 black--text text-break text-truncate"},[_vm._t("item-text",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText])+" ")]},{"item":item})],2),_vm._t("after-text",null,{"item":item})]},{"item":item})],2),_c('VuetifyLazyComponent',{staticClass:"my-0 flex-shrink-0 ml-3 py-0",style:({
            pointerEvents: 'none',
          }),attrs:{"input-value":_vm.isSelectedItem(item[_vm.itemValue]),"ripple":false,"dense":"","hide-details":"","component-name":"VCheckbox"}})],2)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }