import { ORIGIN } from "@config/base";
import { telegramSupportBotLink } from "@config/social";
import es from "vuetify/src/locale/es";

export default {
  ...es,
  dictionary: {
    country: {
      tr: "Turkey",
      ee: "Estonia",
      us: "USA",
    },
  },
  warnings: {
    cards_suspended_update_status: `<b>Cards are suspended</b> -To continue working with cards, remove them from the "Freeze" status.`,
    overdraft_admedia_prepaid_card:
      "Please notice that card balance can go negative because of international transaction fees but not much than {0}!",
    overdraft_admedia_postpaid_card:
      "Please notice that transactions can go through limit because of international transaction fees but not much than {0}!",
  },
  product_tour: {
    prev_btn_text: "Back",
    next_btn_text: "Next",
    done_btn_text: "Done",
    progress_text: "{{current}} of {{total}}",
  },
  errors: {
    can_choose_max: "You can choose max. {0} at once.",
    promocode_not_valid: "Promo code is not valid.",
    promocode_access_blocked:
      "You have exceeded the maximum number of failed attempts. Please try again later.",
    bank_inquiry_disabled: "This BIN is disabled on your account.",
    invalid_invite: "This invite link is invalid or expired.",
    internal_error: "Internal error",
    no_internet_connection: "No internet connection",
    invalid_telegram_login:
      "Please enter your Telegram login. For example, @example",
    invalid_current_password: "Invalid current password",
    invalid_promocode: "Promocode does not exist or is invalid",
    limit_amount_card_issue:
      "The amount of the limit must be greater than the number of cards active ({0})",
    positive_number: "Enter a positive number",
    min_amount_refill: "Minimum top-up amount is {0}",
    min_amount_transfer: "The minimal transfer sum is {0}",
    minimal_card_balance_cannot_be_less:
      "The minimal card balance cannot be less than {0}",
    min_amount: "Minimum amount {0}",
    max_amount: "Maximum amount {0}",
    min_value: "Minimum value {0}",
    max_value: "Maximum value {0}",
    max_сhars: "Maximum number of characters: {0}",
    only_digits: "Enter a numeric value",
    only_integer_number: "Enter an integer",
    try_later: "An error has occurred. Please try later",
    access_denied: "Invalid Email address or password",
    user_already_exists: "User already exists",
    invalid_code: "Wrong code",
    too_many_postpaid_card_issued: `You've reached the maximum number of limit cards. Please contact our support: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    too_many_card_issued_mediabuyer:
      "You've reached the maximum number of active cards. Please contact your Teamlead to increase the limit.",
    too_many_card_issued_teamlead: `You've reached the maximum number of active cards. Please contact our support: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    bank_not_available_support: `This bank is not available for you. Please contact our support: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    user_account_not_found: "User account not found",
    required: "Required to fill out",
    email: {
      incorrect: "Wrong email",
      try_with_another_domain: "Please, try email with another domain",
    },
    password: {
      max_number_change_requests:
        "You have reached the maximum number of password change requests. Please try again later.",
      must_match: "Passwords must match",
      length: `The length of password should be from 8 to 20 Latin characters, the number of one digit and one capital letter`,
    },
    insufficient_balance: "Insufficient balance",
    insufficient_balance_user: `Insufficient funds on the user's account {0}`,
    image_size: "Image size must not exceed {0}kb",
    sms_confirmation_verify_failed: "Incorrect verification code",
    sms_verification_max_attempts_error:
      "You've reached maximum allowed number of attempts to change your phone number. Please try a little bit later",
    card: {
      max_limit_you_can_set: "The maximum limit you can set {0}",
      limit_balance: "You must have at least {0} on your balance",
      total_daily_limit:
        "Total limit must be more than or equal to daily limit.",
    },
    phone_number: {
      required: "Enter your phone number",
      busy_and_try_another:
        "This phone number cannot be used for our service. Please try another one",
    },
  },
  info: {
    transferred_5_minutes: "{0} will be transferred within 5 minutes!",
    promocode_successfully_applied: "Promo code successfully applied!",
    copy_to_clipboard: "Copy to clipboard: {0}",
    contact_support_get_api_doc: "Please contact support to get an API doc.",
    set_limit_amount_success: "Limit successfully set",
    changes_saved: "Your changes have been saved",
    password_changed: "Your password has been changed",
    refill_user_success: "Balance {0} topped up with {1}",
    card_refilled: "Card topped up",
    sure_want_refill_card: "Are you sure you want to top up your card on {0} ?",
    sure_want_refill_account:
      "Are you sure you want to top up the balance of user {0} by {1} ?",
    copied: "Сopied",
    copy: "Copy",
    copy_card_number: "Copy card number",
    card_created: "Card created successfully",
    download_to_csv: "Download to CSV",
    download_to_excel: "Download to EXCEL",
    deleted: "Deleted",
    pay_attention_for_advertising_purposes: `
      Pay attention that cards can only be used for advertising accounts <span class="white--text">({0})</span>.
      Your account and funds will be blocked if you will pay for the third party services.
    `,
    commission_canceled_transactions_non_refundable:
      "Commission for canceled transactions is non-refundable.",
    card_frozen_no_transactions_more_two_weeks:
      "The card will be frozen if there have been no transactions for more than two weeks. The limits must be updated manually if you want to continue working with card.",
    declension: {
      cards: {
        i_1: "card",
        i_2: "cards",
        i_3: "cards",
        i_4: "card",
        close: {
          i_1: "closed",
          i_2: "closed",
          i_3: "closed",
        },
        freeze: {
          i_1: "paused",
          i_2: "paused",
          i_3: "paused",
        },
        unfreeze: {
          i_1: "unpaused",
          i_2: "unpaused",
          i_3: "unpaused",
        },
        unsuspend: {
          i_1: "unfreezed",
          i_2: "unfreezed",
          i_3: "unfreezed",
        },
      },
    },
  },
  locked_page: {
    team: {
      text: `The <b>"Team"</b> section is currently unavailable to you.<br />
      If you want to open this section, please contact our support:`,
    },
    referral_program: {
      text: `The <b>"Referral program"</b> section is currently unavailable to you.<br />
      If you want to open this section, please contact our support:`,
    },
  },
  promocode: {
    type: {
      free_cards_bonus: "Free cards",
      deposit_bonus: "Deposit bonus",
    },
    status: {
      completed: "Used",
      active: "Active",
    },
  },
  team: {
    overall_balance: "Overall team balance",
    sure_want_member: "Are you sure you want to remove {0} from the team?",
    status: {
      active: "Active",
      deleted: "Deleted",
    },
  },
  telegramBanner: {
    title: "Join our group on telegram!",
    subtitle: "Stay up to date with <span>multicards.io</span> news.",
  },
  messages: {
    facebookCode: {
      title: "{0} - Facebook code",
      text: "This code applies to payment by card {0}",
    },
    card3dsСode: {
      title: "3DS code: {0}",
      text: "Card number: {0}<br/>Next page password: {1}",
    },
  },
  fields: {
    to_be_paid: "To be paid",
    total_paid: "Total paid",
    paid: "Paid",
    apply: "Apply",
    promocode_name: "Promocode",
    mediabuyer: "Mediabuyer",
    teamlead: "Teamlead",
    max_number_of_cards: "Max. number of cards",
    out_of: "out of",
    no_limit: "No limit",
    no_daily_limit: "No daily limit",
    no_total_limit: "No total limit",
    number_of_cards: "Number of cards",
    daily_limit: "Daily limit",
    total_limit: "Total limit",
    limit: "Limit",
    deposit_amount: "Deposit amount",
    deposit_amounts: "Deposit amounts",
    deposit_amount_per_card: "Deposit amount per card",
    card_name: "Card name",
    promocode: "Promocode",
    history_promocodes: "History of promocodes",
    min_commission: "Min. commission",
    commission_rate: "Commission",
    card_issue: "Card issue",
    confirmation_code: "Confirmation code",
    firstName: "First Name",
    lastName: "Last Name",
    password: "Password",
    new_password: "New password",
    repeat_new_password: "Repeat new password",
    confirm_password: "Confirm  password",
    forgot_password: "Forgot your password?",
    continue: "Continue",
    back: "Back",
    phone: "Phone number",
    notification: "Notification",
    cancel: "Cancel",
    next: "Next",
    save_changes: "Save Changes",
    change: "Change",
    connect: "Connect",
    disable: "Disable",
    logout: "Logout",
    auth_2auth: "2FA authentication",
    your_password: "Your password",
    current_password: "Current password",
    code_2fa: "2FA code",
    notifications: "Notices",
  },
  error_page: {
    iternal_server_error: {
      subtitle: "Internal Server Error",
      text: "The server encountered an internal error or misconfiguration and was unable to complete your request",
    },
    page_not_found: {
      subtitle: "Page not found",
      text: "The page you are looking for might have been removed had its name changed or is temporarily unavailable",
    },
    service_unavailable: {
      subtitle: "System Under Maintenance",
      text: "We're preparing to serve you better",
    },
    back_to_home: "Go back to main page",
  },
  cookies: {
    text1:
      "Tiene la opción de aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan cookies automáticamente, pero normalmente puede modificar la configuración de su navegador para rechazarlas si lo prefiere. Sin embargo, tenga en cuenta que deshabilitar las cookies puede afectar su capacidad para acceder a ciertas características o funcionalidades del sitio web.",
    text2:
      "Para gestionar sus preferencias de cookies, puede modificar la configuración de su navegador o utilizar la herramienta de gestión de concentración de cookies disponible en su sitio web.",
    accept: "Aceptación",
  },
  landing: {
    nav: {
      benefits: "Las ventajas",
      contact_us: "Contactar con nosotros",
      get_started: "Iniciar",
      log_in: "Autorización",
    },
    into: {
      title: "Las mejores tarjetas virtuales para",
      subtitle:
        "Una solución simple y perfecta para el marketing en línea y el comercio electrónico.",
      learn_more: "Saber más",
      list: {
        item1: {
          title: "Precios bajos",
          subtitle:
            "Más gastos - menos comisiones. Tarjetas ilimitadas. Recargue con criptomoneda y transferencias WIRE.",
        },
        item2: {
          title: "Trabaje con su equipo",
          subtitle:
            "Añade miembros de tu equipo y gestiona tarjetas y presupuestos.",
        },
        item3: {
          title: "Análisis sistemático",
          subtitle:
            "Obtenga informes personales y estadísticas de gastos detalladas para cada miembro del equipo.",
        },
      },
    },
    get_started: {
      title: "Multicards es fácil y cómodo",
      text1:
        "Cuando se active su cuenta, recargue su saldo y cree su primera tarjeta.",
      text2:
        "¡Ahora puede configurar y ejecutar sus campañas publicitarias en cualquier lugar!",
      users: {
        text1: "Más de",
        text2: "Usuarios ya están con nosotros",
        count: "10.5 mil",
      },
      list: {
        item1: {
          title: "Recargue su saldo",
          subtitle:
            "Formas convenientes de recargar su saldo con Wire o criptomoneda.",
        },
        item2: {
          title: "Gestione sus campañas",
          subtitle:
            "Nuestra interfaz fácil de usar le permitirá lanzar su campaña de manera fácil y eficiente.",
        },
        item3: {
          title: "Analice el historial detallado de las transacciones",
          subtitle:
            "Tendrá un historial completo de transacciones con la capacidad de exportar datos.",
        },
      },
    },
    benefits: {
      list: {
        item1: {
          title: "Tarjetas de confianza",
          text: "Más de 7 BINs con emisión ilimitada e instantánea de tarjetas.",
        },
        item2: {
          title: "Tarjetas<br/>de límite",
          text: "Administre sus límites de gasto diarios y compartidos o use el saldo corriente de su cuenta como límite.",
        },
        item3: {
          title: "Tarjetas para<br/>sus necesidades",
          text: "Nuestras tarjetas sirven para trabajar con Facebook, Google, TikTok y muchas otras plataformas de publicidad.",
        },
        item4: {
          title: "Procesamiento detallado de datos ",
          text: "Verá todas las transacciones en tiempo real en su cuenta personal.",
        },
        item5: {
          title: "El soporte<br/>24/7",
          text: "En cualquier momento, independientemente del horario del banco.",
        },
        item6: {
          title: "100%<br/>protegido",
          text: "Autenticación doble y control de gastos para usted y su equipo.",
        },
      },
    },
    contact_us: {
      title: "Contactar con nosotros",
      subtitle:
        "Si tiene alguna pregunta, siempre puede <span>contactar</span> con nuestro equipo de <span>soporte</span> y le contaremos más sobre nuestras <span>ventajas</span>.",
    },
    partners: {
      subtitle: "Nuestros socios",
    },
    footer: {
      title: "¡Únase!",
      subtitle:
        "Suscríbase a nuestro boletín y manténgase al día con las últimas noticias.",
      follow_us: "Suscríbase:",
      terms_of_use: "Los términos del uso",
      privacy_policy: "Política de privacidad",
      copyright:
        "© {0} Multicards. Todos los derechos reservados. DIGITORY GROUP PC, Reino Unido.",
    },
  },
  login: {
    title: "Welcome!",
    subtitle: "Log in to continue",
    dont_have_account: "Don't have an account?",
    join: "Join Multicards.io",
  },
  sign_up: {
    title: "Welcome!",
    subtitle: "Register to continue",
    have_account: "Do you already have an account?",
    login: "Login",
    register: "Register",
  },
  forgot_password: {
    title: "Forgot your password?",
    send_code_again: "Send code again",
  },
  subscribe: "Subscribe",
  verify_account: {
    title: "Hello!",
    text1: `We are glad that you have registered on <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2: `To start exploring <a href="${ORIGIN}" target="_blank">multicards.io</a> we need to verify your account. Please contact us via telegram:`,
  },
  confirm_email: {
    title: "Confirm your e-mail",
    text1: `Welcome to <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2:
      "We've sent you a link to confirm your account via e-mail: <b>{0}</b>",
    resend_link: "Re-send the link",
  },
  blocked_account: {
    title: "Hello!",
    text1:
      "Your account has been blocked. Now you can't login and use this account!",
    text2: "You can always contact us via telegram:",
  },
  feedback_survey: {
    title: "Your opinion matters greatly to us!",
    skip_survey: "Skip questionnaire",
    send_answer: "Send answer",
    completion: {
      title: "Thanks for your reply!",
    },
    question_1: {
      text: `Which Affiliate Program balances would you like to use to have the ability to withdraw funds from to top up <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
    question_2: {
      text: `What payment system do you use besides <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
  },
  dashboard: {
    my_balance: "My balance",
    contact_support: "Contact support",
    main_product_tour: {
      buttons: {
        lets_start: "Let's start",
        skip_intro: "Skip intro",
        finish_intro: "Finish intro",
        move_to_cards: `Move to "Cards" section`,
        move_to_balance_cards: `Move to "Manual Top-up Cards" section`,
        move_to_limit_cards: `Move to "Limit Cards" section`,
        move_to_refill: 'Move to "Refill" section',
        move_to_currency_converter: `Move to "Converter" section`,
        move_to_transactions: `Move to "Transactions" section`,
        move_to_cash_flow: `Move to "Cash flow" section`,
        move_to_team: `Move to "Team" section`,
        move_to_statistic: `Move to "Statistics" section`,
        move_to_profile: `Move to "Profile" section`,
        move_to_additional_info: `Move to "F.A.Q." section`,
        watch_tutorial: "Watch the tutorial",
      },
      sure_skip_intro: "Are you sure you want to skip intro?",
      step1: `
        We are glad to introduce you the multicards.io, virtual card issuing service.<br /><br />

        We've prepared short intro for you. We'll briefly show and describe all the functionality of our service.<br /><br />

        Sure you'll enjoy it 🙂
      `,
      step2: `
        You will always see your account balance on the top of the page.<br /><br />

        Please note that for your convenience we have divided the balances into:<br />
        - Main balance<br />
        - Card balance (Cash loaded to the cards)
      `,
      step3: `
        Also you'll see here  the general indicator of payment rejection - Decline rate.<br /><br />

        This rate will always help you understand your statistics on payment rejections.
      `,
      step4: `
        In the “Cards” section, you can create balance or limit cards.<br /><br />

        Basically only balance cards are available. If you want to open limit cards, contact your account manager.
      `,
      step5:
        "This section has a field for searching cards by name, also you can set up columns you need or filter cards by its owner, BIN and status.",
      step6: "You can export cards data in following formats: CSV, XLS.",
      step7: 'To issue a card, click on the "Issue card" button.',
      step8:
        "This window displays cards available for issuing, basic information for every card BIN.",
      step9:
        "Next, let's choose any BIN that suits your needs and try to issue a card.",
      step10:
        "In the card creation window you have to enter the name of the card, the top-up amount and the total number of cards you want to issue.",
      step11:
        "Below you can see the total amount of the card issuance transaction.",
      step12:
        "In this field you'll see tariffs and conditions for the chosen BIN.",
      step13:
        "After creating a balance card, you can see all the necessary information: card number, CVC, expire date, status, current balance and expense.",
      step14:
        "You can always top up card balance or withdraw funds back to main balance from your card.",
      step15:
        '"Auto-replenishment" is a convenient function which helps you control your cards balance. You can set up automatic replenishment of your balance card to avoid declined payments.',
      step16:
        "Near each card there is a hamburger menu which hides additional functionality.",
      step17:
        "An option for quickly viewing all transactions on a chosen card.",
      step18: "An option for quickly viewing statistics for this card.",
      step19: '"Pause" function for the card.',
      step20:
        '"Close card" button. After that all remaining funds will be transfered back to main account balance.',
      step21: ` 
        Similar functionality can be found in the “Limit Cards” section.<br />
        The main difference is after payment funds are debited from the main account balance.<br /><br />

        You can set up 2 options on card:<br />
        - Daily limit<br />
        - Total limit
      `,
      step22: "The card limit is setting up manually.",
      step23: `
        Here you can set limits on the current card or link these limits to your account balance.<br /><br /><br />

        If you set the value "No daily limit", then the value "Daily limit" will be equal to the account balance.<br /><br />
        If you set the value "No total limit", then the value "Total limit" will be equal to the account balance.
      `,
      step24:
        'In the "Replenishment" section you can always top up your account balance.',
      step25:
        "You can top up your balance using Capitalist payment system or USDT (Trc20) transfer.",
      step26:
        "We're recommend to use USDT trasfer due to lower replenishment fee.",
      step27:
        "In this window, you can calculate the amount that will be credited to your balance.",
      step28:
        "Be sure to copy exactly this wallet, its linked to your account!",
      step29:
        "In the “Converter” subsection you can exchange currency from your main account balance.",
      step30: "USD ⭢ EUR и EUR ⭢ USD conversions are currently available.",
      step31:
        'In the "Transactions" section you will see all transactions that relate to cards and card balance.',
      step32: `
        The main filters are located at the top:<br /><br />
        - Filter by date<br />
        - Filter by users<br />
        - Filter by cards
      `,
      step33: `
        Additional filters are located here:<br /><br />
        - Filter by currency<br />
        - Filter by payment status<br />
        - Filter by type of transaction
      `,
      step34: "You can also export transaction report in CSV or XLS.",
      step35: "Transaction example.",
      step36: `
        In the "Cash Flow" section, you'll see all transactions that affect your main balance.<br /><br />

        List of all operations:<br />
        - Currency conversion<br />
        - Transaction fee<br />
        - Replenishment of internal balance<br />
        - Card replenishment<br />
        - Transfer from card to main balance<br />
        - Money transfer<br />
        - Incoming funds transfer<br />
        - Card creation fee<br />
        - Fee for declined payment<br />
        - Refund per transaction<br />
        - Refund of a transaction from a closed card<br />
        - Closing the card. Return of unused funds
      `,
      step37: `
        The main filters are located at the top:<br /><br />
        - Filter by date<br />
        - Filter by users
      `,
      step38: `
        Additional filters are located here:<br /><br />
        - Filter by currency<br />
        - Filter by type of operation
      `,
      step39: "You can also export report in CSV or XLS.",
      step40: "Operation example.",
      step41: `
        The “Team” section is where you manage your team.<br /><br />

        To access the command interface, contact your account manager.
      `,
      step42: `
        Here is located the invitation link that you need to send to your media buyer. He will automatically be added to your team after registration.<br /><br />

        The link can be copied or regenerated.
      `,
      step43:
        "You can set a limit by number of active cards for each team member.",
      step44:
        "You can top up your media buyer account balance in this section.",
      step45:
        "An automatic replenishment function for the media buyer main account balance is also available.",
      step46: `
        You can always remove a media buyer from the team.<br /><br />

        Please note that all his cards will be automatically closed and the remaining balance will be transferred to your account.
      `,
      step47:
        "The “Statistics” section displays all summary information about transactions on payments, holds, receipts, returns and commissions.",
      step48: `
        Above you can find different filters for your convenience:<br /><br />
        - Filter by date<br />
        - Filter by users<br />
        - Filter by cards<br />
        - Filter by banks<br />
        - Filter by currency
      `,
      step49: `
        Be careful when choosing a grouping of statistics.<br /><br />

        This report can be grouped by team members or by date.
      `,
      step50: "You can also export report in CSV or XLS.",
      step51:
        'The "Profile" section contains basic information about your account and several additional functions.',
      step52:
        "You can link a phone number to your account in order to use BINs with 3ds.",
      step53: `
        For security reasons you can enable 2FA.<br /><br />

        We recommend using this method to protect your account.
      `,
      step54: `
        It is also possible to disable the limit of 1 active session on your account.<br /><br />

        We recommend turning off the limit only if 2FA is active.
      `,
      step55: `
        Click on this button will redirect you to the support bot of the Multicards.io service.<br /><br />

        Our support managers will help you resolve any issue regarding the service as soon as possible.
      `,
      step56:
        "F.A.Q. section contains answers to all basic questions about working with the service and its conditions.",
      step57: `
        All important multicards.io news and updates is published here.<br /><br />

        In this section you can always see up-to-date information.
      `,
      step58: `
        This is where our short guide comes to an end!<br /><br />

        We do everything to make it convenient for you to work with virtual cards.<br />
        You can always text to our support or your personal account manager with any question!
      `,
    },
    users: {
      title: "Users",
    },
    change_card_balance: {
      title: "Change balance",
      from: "From",
      to: "To",
    },
    auto_refill: {
      title: "Autorefill",
      enabled: "Autorefill enabled",
      disabled: "Autorefill disabled",
      threshold: "If the balance below",
    },
    confirmation_code: {
      request_again_via: "Request a new code in {0} sec.",
      request_again: "Request the code again",
      get: "Get code",
    },
    phone_number: {
      approved: "Phone number has been confirmed",
      verify_phone: "Confirm your phone number",
      bind: "Link",
      sure_want_unbind:
        "Are you sure you want to unlink your phone number {0} ?",
      sure_want_change:
        "Are you sure you want to change your current phone number to {0} ?",
      used_for_vidar: `This phone number is used for 3ds messages only to Vidar (EUR).<br />Currently it works exclusively for Vidar (EUR)`,
    },
    statistic: {
      groupBy: {
        label: "Group by",
        date: "Date",
        mediabuyer: "Mediabuyer",
        teamlead: "Teamlead",
      },
    },
    welcome: {
      title: "We are glad that you decided to use our service!",
      refill: {
        text: "The first deposit up to<br /><b>{0}</b> without commission<br />especially for you!",
      },
      issueCard: {
        text: "Release of the <b>first {0} cards</b> for free especially for you!",
      },
      support: {
        text: "Join us on <b>telegram!</b><br />Stay up to date with the latest news.",
      },
    },
    user: {
      role: {
        accountant: "Accountant",
        teamlead: "Teamlead",
        owner: "Owner",
        mediabuyer: "Mediabuyer",
      },
    },
    limit_per_active_session: "Limit per 1 active session",
    encrypt_card_data: "Encrypt card data",
    facebook_code_notifications: "Facebook code notifications",
    hide_team_members_cards: "Hide team members cards",
    change_profile_image: "Change profile image",
    avatar_objects: "Objects",
    avatar_animals: "Animals",
    avatar_people: "People",
    news: {
      title: "News",
      show_more: "Show more",
      show_less: "Show less",
    },
    datepicker: {
      today: "Today",
      yesterday: "Yesterday",
      last_3_days: "Last 3 days",
      last_7_days: "Last 7 days",
      last_14_days: "Last 14 days",
      current_month: "Current month",
      last_month: "Last 1 month",
      prev_month: "Prev month",
      last_3_months: "Last 3 months",
      last_6_months: "Last 6 months",
      custom: "Custom",
    },
    card: {
      batch_issue: {
        comming_soon: `Your cards are almost ready!<br /> They will appear in the {icon} <b>"Cards"</b> section soon.`,
        pending: 'The card group <b>"{0}"</b> is created. Wait, please...',
        success: 'Card group <b>"{0}"</b> has been created!',
        confirm_cancel: {
          title: 'Are you sure you want to cancel group card issue "{0}" ?',
        },
      },
      batch_update: {
        pending: {
          close_cards: "{0} / {1} cards closed.",
          freeze_cards: "{0} / {1} cards paused.",
          unfreeze_cards: "{0} / {1} cards unpaused.",
          unsuspend_cards: "{0} / {1} cards unfreezed.",
        },
        success: {
          close_cards: "{0} {1} successfully {2}!",
          freeze_cards: "{0} {1} successfully {2}!",
          unfreeze_cards: "{0} {1} successfully {2}!",
          unsuspend_cards: "{0} {1} successfully {2}!",
        },
        are_you_sure: {
          close_cards: "Are you sure you want to<br />closed {0} {1}?",
          freeze_cards: "Are you sure you want to<br />paused {0} {1}?",
          unfreeze_cards: "Are you sure you want to<br />unpaused {0} {1}?",
          unsuspend_cards: "Are you sure you want to<br />unfreezed {0} {1}?",
          cancel_action: "Are you sure you want to cancel the action?",
        },
      },
    },
    qrcode: {
      connect_info:
        "Point your phone's camera at the QR code from the Google Authenticator app and enter the received code",
    },
    billing: {
      status: {
        created: "Created",
        success: "Success",
        error: "Error",
        expired: "Expired",
      },
      usdt_transfer: {
        title:
          "You can replenish your account by transferring USDT according to these payment details.",
        text1: `
          Top-up instruction:<br/>
          1. Copy the receiving address<br/>
          2. Replenish your funds to the wallet above<br/>
          3. Contact our support team <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a><br/>
          4. Make sure that you send <b>hash ID</b> to our manager
        `,
        text2: `
          Please note that your account will not be funded until we receive <b>confirmation of the transaction</b>.<br />
          Min. deposit is {0} USDT.
        `,
        amount_out: "I want to pay {0}",
        amount_in: "I will receive {0}",
        address_wallet: "Wallet address",
        refill_commission: "Top up fee is {0}% + {1} USDT.",
        min_amount: "Minimum top-up amount is {0} USDT",
        warning:
          "<b>Carefully</b> check wallet details. Funds will be credited in 🕒 15-30 minutes.",
      },
    },
    hide: "Hide",
    refunded: "Refunded",
    selected_mediabuyer_from_to: "{0} / {1} mediabuyers",
    selected_from_to: "{0} / {1} selected",
    selected_count_label_1: "{0} {1} selected",
    selected_count_label_2: "{0} {1} selected",
    view_cards: "View cards",
    back_to_cards: "Back to cards",
    show_all_cards: "Show all cards",
    choose_bank: "Choose a bank",
    choose_or_add_card_group: "Select or add a new group",
    amount_debited_from_your_balance:
      "Amount that will be debited from your balance",
    transfer_from: "From",
    transfer_to: "To",
    money_transaction: "Money transaction",
    set: "Set",
    set_limit: "Set limit",
    delete: "Delete",
    activate: "Activate",
    to_refill: "Refill",
    transfer: "Transfer",
    team_statistics: "Team stats",
    lang: "Language",
    refill: "Refill",
    close_card: "Close card",
    pause_card: "Pause",
    play_card: "Unpause",
    unfreeze_card: "Unfreeze card",
    unfreeze: "Unfreeze",
    issue_card: "Issue card",
    issue_limit_card: "Issue limit card",
    filters: "Filters",
    settings: "Settings",
    table_columns: "Columns in the table",
    clear: "Clear",
    clear_all: "Clear all",
    choose_all: "Select all",
    search: "Search",
    card_n: "Card {0}",
    submit: "Submit",
    show: "Show",
    show_all: "Show all",
    accept: "Accept",
    close: "Close",
    save: "Save",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    card_deposit: "Refill card",
    transfer_to_balance: "Transfer to balance",
    bank_info: {
      odin: {
        auto_closed_during_one_month:
          "Please pay attention that the cards on this BIN will be automatically closed if there were no transactions during one month.",
      },
    },
    transaction: {
      status: {
        approved: "Approved",
        settled: "Settlement",
        declined: "Decline",
        pending: "Pending",
      },
      type: {
        payment: "Payment",
        refund: "Refund",
        unknown: "Other",
        authorization: "Authorization",
        refill: "Income",
        commission: "Commission",
      },
      cardType: {
        prepaid: "Manual top-up",
        postpaid: "Limit cards",
      },
    },
    cash_flow: {
      type: {
        funds_transfer: "Money transfer",
        card_issue: "Card creation fee",
        transaction_fee: "Transaction fee",
        int_transaction_fee: "International transaction fee",
        card_deposit: "Refill card",
        funds_deposit: "Refill internal balance",
        incoming_funds_transfer: "Incoming money transfer",
        transaction_refund: "Transaction refund",
        funds_exchange: "Currency conversion",
        decline_fee: "Decline fee",
        card_close: "Closing the card. Refund of unused funds from the balance",
        transaction_refund_to_balance: "Transaction refund from closed card",
        card_withdraw: "Сard withdrawal",
      },
    },
    currency_converter: {
      title: "Currency converter",
      from_currency: "From currency",
      to_currency: "To currency",
      amount: "Amount",
      receive_amount: "Amount you will receive",
      exchange_rate_valid: "Exchange rate valid {0}s",
      convert: "Convert",
      are_you_sure: `Are you sure you want to convert <span class="text-no-wrap">{0} ⭢ {1} ?</span>`,
      successful_conversion: "Successful conversion",
    },
    table: {
      header: {
        on_hold: "On hold",
        from: "From",
        to: "To",
        closed_by: "Closed by",
        income: "Income",
        comment: "Comment",
        role: "Role",
        bank: "Bank",
        banks: "Banks",
        expire: "Expires at",
        name: "Name",
        number_of_cards: "Number of cards",
        number_of_active_cards: "Number of active cards",
        expenditure: "Expenditure",
        date: "Date",
        card_number: "Card number",
        issued_at: "Issued at",
        status: "Status",
        cardType: "Card type",
        owner: "Owner",
        spend: "Spend",
        balance: "Balance",
        card_balance: "Card balance",
        currency: "Currency",
        amount: "Amount",
        transaction_fee: "Transaction fee",
        another_transaction_fee: "International transaction fee",
        additional_transaction_fee_another_currency:
          "Additional international transaction fee",
        transaction_fee_with_currency: "Transaction fee ({0})",
        min_transaction_fee: "Min. transaction fee",
        decline_transaction_fee: "Decline fee",
        fixed_fee_for_any_operation: "Fixed fee for any operation",
        commission_rate: "Commission",
        commission_rate_for_cards: "Commission for cards",
        type: "Type",
        description: "Description",
        wallet: "Wallet",
        account: "Account",
        limit_amount: "Limit amount",
        payment_method: "Payment system",
        payments: "Payments",
        refunds: "Refunds",
        transactions: "Transactions",
        transactions_amount: "Transactions (amount)",
        statistic_amount: "Amount",
        statistic_decline: "Decline",
        statistic_decline_amount: "Decline (Amount)",
        statistic_pending: "Approved | Pending",
        statistic_pending_amount: "Approved | Pending (Amount)",
        statistic_refunded: "Refunded",
        statistic_refunded_amount: "Refunded (Amount)",
        statistic_approved: "Approved | Settlement",
        statistic_approved_amount: "Approved | Settlement (Amount)",
        declined: "Declined",
        total: "Total",
        numTransaction: "Number of transactions",
        numDeclinedTransaction: "Number of rejected transactions",
        daily_spend: "Daily spend",
        daily_limit: "Daily limit",
        group_name: "Group",
        total_spend: "Total spend",
        total_limit: "Total limit",
        bin_name: "BIN name",
        bin_id: "BIN ID",
        zip_info: "ZIP info",
        recommendation: "Recommendation",
        supervisor: "Supervisor",
      },
    },
    sidebar: {
      profile: "Profile",
      cards: "Cards",
      reports: "Reports",
      my_balance: "My balance",
      referral_program: "Referral program",
      converter: "Converter",
      transactions: "Transactions",
      cash_flow: "Cash flow",
      refill: "Refill",
      team: "Team",
      statistic: "Statistic",
      statistic_by_users: "Users",
      statistic_by_cards: "Cards",
      statistic_by_status: "By status",
      statistic_by_type: "By type",
      support: "Support",
      card_with_limit: "Limit cards",
      card_with_balance: "Manual top-up",
      partners: "Partners",
    },
    profile: {
      notification: {
        by_email: "By Email",
        by_telegram: "By Telegram",
      },
    },
    cards: {
      title: "Cards",
      status: {
        active: "Active",
        closed: "Closed",
        freeze: "Pause",
        suspend: "Freeze",
      },
    },
    faq: {
      title: "Frequently Asked Questions",
    },
  },
};
