export enum MessageType {
  FACEBOOK_CODE_EVENT = "FacebookCodeEvent",
  CARD_3DS_CODE_EVENT = "Card3dsCodeEvent",
}

export type Message =
  | {
      id: string;
      type: MessageType.FACEBOOK_CODE_EVENT;
      createdAt: string;
      closedAt?: string;
      content: {
        code: string;
        amount: number;
        cardId: number;
        cardMask: string;
      };
    }
  | {
      id: string;
      type: MessageType.CARD_3DS_CODE_EVENT;
      createdAt: string;
      closedAt?: string;
      content: {
        code: string;
        amount: number;
        cardId: number;
        cardMask: string;
      };
    };
